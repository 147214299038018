<template>
  <div class="protocol">
      <portlet title="سالون يازما ئۇچۇرىغا ئېرىشىش">
        <template v-slot:body>
          <we-chat-news-info :need-view="true" v-model="url" :auto-load="!!url" />
        </template>
      </portlet>
  </div>
</template>

<script>
  import Portlet from "../../../partials/content/Portlet";
  import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs.module";
  import WeChatNewsInfo from "../../../components/tools/WeChatNewsInfo";
  import ParseWeChatNewsInfo from "../../../components/modals/ParseWeChatNewsInfo";
  export default {
    name: "index",
    components: { Portlet, WeChatNewsInfo, ParseWeChatNewsInfo },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "قوللىنىشچان قوراللار", route: "/tools" },
        { title: "سالون يازما ئۇچۇرىغا ئېرىشىش" }
      ]);
    },
    data(){
      return {
        url: '',
        test: '',
        modalState: false
      }
    },
    methods: {
      onClickTest(){
        this.modalState = true
      },
      onCloseModal(){
        this.modalState = false
      },
      handleSuccess(response){
        // console.log('handleSuccess')
        // console.log(response)
      },
      handleFail(response){
        // console.log('handleFail');
        // console.log(response);
      }
    }
  }
</script>

<style scoped>

</style>
